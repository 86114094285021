import * as React from 'react';
import {
  FormHelperText,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';

export interface PasswordFieldProps {
  password: { text: string; error: string };
  setPassword: (props: { text: string; error: string }) => void;
  textFieldVariant?: 'outlined' | 'filled' | 'standard';
  loading: boolean;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  password,
  setPassword,
  textFieldVariant = 'filled',
  loading,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <FormControl margin="none" fullWidth error={Boolean(password.error)}>
      <TextField
        placeholder={textFieldVariant === 'outlined' ? 'Password' : ''}
        label={textFieldVariant !== 'outlined' && 'Password'}
        error={Boolean(password.error)}
        variant={textFieldVariant}
        value={password.text}
        disabled={loading}
        onChange={(e) => {
          setPassword({ text: e.target.value, error: '' });
        }}
        type={!showPassword ? 'password' : 'text'}
        InputProps={{
          startAdornment: textFieldVariant === 'outlined' && (
            <InputAdornment position="start">
              <LockIcon color={password.error ? 'error' : 'action'} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password" onClick={togglePassword}>
                {React.createElement(
                  showPassword ? VisibilityIcon : VisibilityOffIcon,
                  {
                    color: password.error ? 'error' : 'action',
                  },
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText>{password.error || ' '}</FormHelperText>
    </FormControl>
  );
};
export default React.memo(PasswordField);
