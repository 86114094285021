import React from 'react';
import { Box } from '@mui/material';

export const SvgIcon = () => (
  <Box
    sx={{
      width: 300,
      height: 300,
      mb: 6,
      mx: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
    }}
  >
    <svg viewBox="0 0 101 34" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.7902 26.0734C71.5687 25.6308 71.7476 22.1704 71.7476 19.555V13.8817H77.3157C77.7531 13.8817 78.1111 13.5195 78.1111 13.0769V7.04142C78.1111 6.59882 77.7531 6.23669 77.3157 6.23669H71.7476V0.804734C71.7476 0.36213 71.3897 0 70.9522 0H64.2905C63.853 0 63.495 0.36213 63.495 0.804734C63.495 5.02959 63.495 15.3302 63.495 18.6095C63.495 22.0296 63.3558 25.7716 65.0262 28.8698C66.8557 32.2497 70.6738 33.7586 74.3726 33.7988H79.702C80.1394 33.7988 80.4974 33.4367 80.4974 32.9941V27.0592C80.4974 26.6166 80.1394 26.2544 79.702 26.2544C78.0912 26.2544 76.3214 26.2746 74.7902 26.0734Z"></path>
      <path d="M95.2726 26.0734C92.0511 25.6308 92.2301 22.1704 92.2301 19.555V13.8817H97.7981C98.2356 13.8817 98.5935 13.5195 98.5935 13.0769V7.04142C98.5935 6.59882 98.2356 6.23669 97.7981 6.23669H92.2301V0.804734C92.2301 0.36213 91.8721 0 91.4346 0H84.7729C84.3354 0 83.9775 0.36213 83.9775 0.804734C83.9775 5.02959 83.9775 15.3302 83.9775 18.6095C83.9775 22.0296 83.8383 25.7716 85.5087 28.8698C87.3382 32.2497 91.1562 33.7586 94.855 33.7988H100.184C100.622 33.7988 100.98 33.4367 100.98 32.9941V27.0592C100.98 26.6166 100.622 26.2544 100.184 26.2544C98.5736 26.2544 96.8038 26.2746 95.2726 26.0734Z"></path>
      <path d="M45.5181 6.2168C37.9416 6.2168 31.7969 12.4334 31.7969 20.0985C31.7969 27.7635 37.9416 33.9801 45.5181 33.9801C53.0946 33.9801 59.2393 27.7635 59.2393 20.0985C59.2393 12.4334 53.1145 6.2168 45.5181 6.2168ZM45.5181 26.6973C41.9187 26.6973 38.9955 23.7399 38.9955 20.0985C38.9955 16.457 41.9187 13.4996 45.5181 13.4996C49.1174 13.4996 52.0406 16.457 52.0406 20.0985C52.0406 23.7399 49.1174 26.6973 45.5181 26.6973Z"></path>
      <path d="M19.2698 0.804734V7.44379C9.94331 3.25917 -0.675711 10.8639 0.0401784 21.1243C0.716296 30.8414 11.2955 36.9373 19.8862 32.5314C24.4798 30.1373 27.403 25.3692 27.4229 20.1385V0.804734C27.4229 0.36213 27.065 0 26.6275 0H20.0652C19.6078 0 19.2698 0.36213 19.2698 0.804734ZM13.7813 26.697C10.1819 26.697 7.25873 23.7396 7.25873 20.0982C7.25873 16.4568 10.1819 13.4994 13.7813 13.4994C17.3806 13.4994 20.3038 16.4568 20.3038 20.0982C20.264 23.7396 17.3607 26.6568 13.7813 26.697Z"></path>
    </svg>
  </Box>
);
