import * as React from 'react';
import { Box, Button, FormControl } from '@mui/material';
import { SvgIcon } from '../SvgIcon';
import EmailField from './Email';
import PasswordField from './Password';
import check from './check';

type InitialType = { text: string; error: string };

export interface LoginProps {
  handleLogin: (email: string, password: string) => any;
  textFieldVariant?: 'outlined' | 'filled' | 'standard';
  emailValidator?: (value: string) => boolean;
  passwordValidator?: (value: string) => boolean;
}

const INITIAL: InitialType = { text: '', error: '' };

const SignIn: React.FC<LoginProps> = ({
  handleLogin,
  textFieldVariant = 'filled',
  emailValidator = (e: unknown) => !!e,
  passwordValidator = (e: unknown) => !!e,
}) => {
  const [email, setEmail] = React.useState<InitialType>(INITIAL);
  const [password, setPassword] = React.useState(INITIAL);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      ![
        check(email, setEmail, emailValidator),
        check(password, setPassword, passwordValidator),
      ].every((v) => v)
    )
      return;
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (typeof handleLogin !== 'function') handleLogin = () => {};
    handleLogin(email.text, password.text);
    setLoading(false);
  };

  return (
    <Box
      m="auto"
      sx={{
        width: {
          xs: 0.9,
          sm: 0.75,
          md: 0.55,
          lg: 0.45,
          xl: 1 / 3,
        },
      }}
    >
      <SvgIcon />
      <form onSubmit={handleSubmit} action="#">
        <FormControl margin="normal" fullWidth>
          <EmailField {...{ email, setEmail, textFieldVariant, loading }} />
          <PasswordField
            {...{ password, setPassword, textFieldVariant, loading }}
          />

          <Button
            type="submit"
            style={{ textTransform: 'none' }}
            size="large"
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
          >
            Sign In
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};
export default SignIn;
