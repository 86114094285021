import React, { useState } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Snackbar, Alert } from '@mui/material';

import SignIn from './components/SignIn/index';

import './css/login.css';

interface LoginResponse {
  googleMapsApiKey: string;
  idToken: string;
}

const theme = createTheme();

export default function Root() {
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const showErrorAlert = () => {
    setErrorAlert(true);
  };

  const handleClose = () => {
    setErrorAlert(false);
  };

  const handleLogin = async (email: string, password: string) => {
    const loginData = { email: email, password: password };
    axios
      .post<LoginResponse>('/login', { ...loginData })
      .then((response) => {
        const { googleMapsApiKey, idToken } = response.data;
        localStorage.setItem('googleMapsApiKey', googleMapsApiKey);
        localStorage.setItem('idToken', idToken);

        const params = new URLSearchParams(document.location.search);
        const backURL = params.get('ret');
        window.location.href = backURL || '/';
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        showErrorAlert();
      });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <SignIn handleLogin={handleLogin} />
        <Snackbar
          open={errorAlert}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </React.Fragment>
  );
}
